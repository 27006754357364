<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Horarios de Delivery</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          @change="getCities"
          style="width: 90%"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-8"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCity"
          :items="cities"
          style="width: 90%"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          @change="getSchedule"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-8"
        />
      </v-col>
    </v-row>

    <div v-if="selectedCity">
      <v-row v-for="(day, index) of days" :key="index" class="ma-5 ml-10">
        <div class="day-name">
          <h3>
            Horarios del <b>{{ day.text }}</b>
          </h3>
        </div>
        <v-col cols="12" sm="6" md="6">
          <v-switch
            :loading="loading"
            v-model="schedule[`${day.value}Active`]"
            label="¿Abre este día? "
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <h3 class="mt-5" v-if="!schedule[day.value].length && schedule[`${day.value}Active`]">
            Aún no se ha configurado el horario para este día.
            <a @click="add(day.value, 0)" class="ml-2"
              ><i class="fas fa-plus"></i> Agregar Horario</a
            >
          </h3>

          <v-row v-for="(item, i) of schedule[day.value]" :key="i">
            <v-col cols="4" sm="4">
              <v-select
                rounded
                :loading="loading"
                :disabled="!schedule[`${day.value}Active`]"
                label="Abre"
                outlined
                :items="allowedTime"
                v-model="item.opensAt"
              />
            </v-col>
            <v-col cols="1" sm="1">
              <i class="fas fa-minus mt-4"></i>
            </v-col>
            <v-col cols="4" sm="4">
              <v-select
                rounded
                :loading="loading"
                :disabled="!schedule[`${day.value}Active`]"
                label="Cierra"
                outlined
                :items="allowedTime"
                v-model="item.closesAt"
              />
            </v-col>
            <v-col cols="1" sm="1" md="1">
              <v-btn
                @click="add(day.value, i)"
                :loading="loading"
                :dark="$vuetify.theme.dark"
                :disabled="!schedule[`${day.value}Active`]"
                fab
                small
                color="primary"
              >
                <v-icon dark> fas fa-plus </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="1" sm="1" md="1">
              <v-btn
                @click="remove(day.value, i)"
                :disabled="!schedule[`${day.value}Active`]"
                :loading="loading"
                fab
                :dark="$vuetify.theme.dark"
                small
                color="#aeaeae"
              >
                <v-icon dark> fas fa-times </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="save-schedule" cols="12" sm="12">
          <v-btn
            @click="save"
            :loading="loading"
            width="200px"
            min-height="40px"
            color="primary"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { db } from "@/firebase";

export default {
  name: "menu-delivery",
  data() {
    return {
      loading: true,
      countries: [],
      selectedCountry: null,
      selectedCity: null,
      snackbar: false,
      snackbarText: "",
      cities: [],
      days: [
        { text: "Lunes", value: "monday", active: true },
        { text: "Martes", value: "tuesday", active: true },
        { text: "Miércoles", value: "wednesday", active: true },
        { text: "Jueves", value: "thursday", active: true },
        { text: "Viernes", value: "friday", active: true },
        { text: "Sábado", value: "saturday", active: true },
        { text: "Domingo", value: "sunday", active: true },
      ],

      allowedTime: [
        { text: "07:00 AM", value: "07:00:00" },
        { text: "07:30 AM", value: "07:30:00" },
        { text: "08:00 AM", value: "08:00:00" },
        { text: "08:30 AM", value: "08:30:00" },
        { text: "09:00 AM", value: "09:00:00" },
        { text: "09:30 AM", value: "09:30:00" },
        { text: "10:00 AM", value: "10:00:00" },
        { text: "10:30 AM", value: "10:30:00" },
        { text: "11:00 AM", value: "11:00:00" },
        { text: "11:30 AM", value: "11:30:00" },
        { text: "12:00 PM", value: "12:00:00" },
        { text: "12:30 PM", value: "12:30:00" },
        { text: "01:00 PM", value: "13:00:00" },
        { text: "01:30 PM", value: "13:30:00" },
        { text: "02:00 PM", value: "14:00:00" },
        { text: "02:30 PM", value: "14:30:00" },
        { text: "03:00 PM", value: "15:00:00" },
        { text: "03:30 PM", value: "15:30:00" },
        { text: "04:00 PM", value: "16:00:00" },
        { text: "04:30 PM", value: "16:30:00" },
        { text: "05:00 PM", value: "17:00:00" },
        { text: "05:30 PM", value: "17:30:00" },
        { text: "06:00 PM", value: "18:00:00" },
        { text: "06:30 PM", value: "18:30:00" },
        { text: "07:00 PM", value: "19:00:00" },
        { text: "07:30 PM", value: "19:30:00" },
        { text: "08:00 PM", value: "20:00:00" },
        { text: "08:30 PM", value: "20:30:00" },
        { text: "09:00 PM", value: "21:00:00" },
        { text: "09:30 PM", value: "21:30:00" },
        { text: "10:00 PM", value: "22:00:00" },
        { text: "10:30 PM", value: "22:30:00" },
        { text: "11:00 PM", value: "23:00:00" },
        { text: "11:30 PM", value: "23:30:00" },
        { text: "12:00 AM", value: "00:00:00" },
        { text: "12:30 AM", value: "00:30:00" },
        { text: "01:00 AM", value: "01:00:00" },
        { text: "01:30 AM", value: "01:30:00" },
        { text: "02:00 AM", value: "02:00:00" },
        { text: "02:30 AM", value: "02:30:00" },
        { text: "03:00 AM", value: "03:00:00" },
        { text: "03:30 AM", value: "03:30:00" },
        { text: "04:00 AM", value: "04:00:00" },
        { text: "04:30 AM", value: "04:30:00" },
        { text: "05:00 AM", value: "05:00:00" },
        { text: "05:30 AM", value: "05:30:00" },
        { text: "06:00 AM", value: "06:00:00" },
        { text: "06:30 AM", value: "06:30:00" },
      ],

      schedule: {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      },
    };
  },

  methods: {
    add(day, index) {
      this.schedule[day].splice(index + 1, 0, { opensAt: "", closesAt: "" });
    },
    remove(day, index) {
      this.schedule[day] = this.schedule[day].filter((item, i) => i != index);
    },

    getCities(countryId) {
      this.categories = [];
      this.cities = [];
      this.selectedCity = "";

      db.collection("cities")
        .where("deleted", "==", false)
        .where("countryId", "==", countryId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let city = item.data();
            city[".key"] = item.id;
            this.cities.push(city);
          });
        });
    },

    async getSchedule(cityId) {
      this.schedule = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
      };

      let city = this.cities.find((item) => item[".key"] == cityId);
      this.schedule = city.deliverySchedule
        ? city.deliverySchedule
        : this.schedule;
    },

    save() {
      this.loading = true;

      this.days.forEach((day) => {
        this.schedule[`${day.value}Active`] = this.schedule[`${day.value}Active`];
        this.schedule[day.value] = this.schedule[day.value].filter(
          (item) => item.opensAt && item.closesAt
        );
      });

      db.collection("cities")
        .doc(this.selectedCity)
        .update({
          deliverySchedule: this.schedule,
        })
        .then(() => {
          this.snackbarText = "Horarios actualizados exitosamente.";
          this.snackbar = true;
          this.loading = false;
        })
        .catch(() => {
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
          this.snackbar = true;
          this.loading = false;
        });
    },
  },

  mounted() {
    this.$store.state.visibleSearch = false;

    db.collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
        this.loading = false;
      });
  },
};
</script>


<style lang="scss" scoped>
.day-name {
  width: 100%;
  margin-bottom: 10px;
  background-color: rgba(166, 166, 166, 0.265);
  padding: 10px;

  h3 {
    // font-weight: bold;
  }
}

.fa-minus {
  color: rgba(128, 128, 128, 0.68);
}

.fa-times {
  color: white !important;
}

.save-schedule {
  display: flex;
  justify-content: flex-end;
  padding-right: 35px;
}
</style>
